import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { AuthorizatedUserGuard } from './core/guards/authorizated-user.guard';

import { MainComponent }   from './main/main.component';
import { AuthComponent }   from './auth/auth.component';
import { HorizontalLayoutComponent } from './horizontal-layout/horizontal-layout.component';
import { WhatsappMultiComponent } from './whatsapp-multi/whatsapp-multi.component';
import { AlasOrderTrackingComponent } from './alas-order-tracking/alas-order-tracking.component';
import { CodControlComponent } from './modules/cod-control/cod-control.component';


export const AppRoutes: Routes = [
   {
      path: 'session/order/client',
      component: AlasOrderTrackingComponent
   },
   {
      path: 'whatsapp-multi',
      component: WhatsappMultiComponent
   },
   { 
      path: 'session/order/client/:id', 
      component: AlasOrderTrackingComponent 
   },
   {
      path: '',
      redirectTo: 'user-profile',
      pathMatch: 'full',
   },
   {
      path: 'session',loadChildren: () =>
      import('./session/session.module').then(m =>m.SessionDemoModule)
   },
   {
   path: '',
   component: MainComponent,
   canActivate: [AuthGuard],
   runGuardsAndResolvers: 'always',
   children: [{
      path: 'orders', loadChildren: ()=> 
      import('./modules/orders/orders.module').then(m => m.OrdersModule),
      canActivate: [ AuthorizatedUserGuard ],
      data: {
         permission: { only: 1, redirectTo: 'user-profile' }
      }
   },{
      path: 'checks', loadChildren: ()=> 
      import('./modules/checks/checks.module').then(m => m.ChecksModule),
      canActivate: [ AuthorizatedUserGuard ],
      data: {
         permission: { only: 2, redirectTo: 'user-profile' }
      }
   },{
      path: 'cards', loadChildren: ()=> 
      import('./modules/cards/cards.module').then(m => m.CardsModule),
      canActivate: [ AuthorizatedUserGuard ],
      data: {
         permission: { only: 3, redirectTo: 'user-profile' }
      }
   },{
      path: 'tags', loadChildren : () => 
      import('./modules/tags/tags.module').then(m=> m.TagsModule)
   },{
      path: 'import', loadChildren: ()=> 
      import('./modules/import/import.module').then(m => m.ImportModule)
   },{
      path: 'user-profile', loadChildren: ()=> 
      import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
   },{
      path: 'permission', loadChildren: ()=> 
      import('app/permission/permission.module').then(m => m.PermissionModule),
   },{
      path: 'log-error', loadChildren: ()=> 
      import('app/log-error/log.module').then(m => m.LogModule),
   },{
      path:'cod-control', loadChildren: ()=>
      import('./modules/cod-control/cod-control.module').then(m => m.CodControlModule),
   }
   ],
   },
   {
      path: 'admin',
      component: HorizontalLayoutComponent,
      children: [{
         path: '',loadChildren: ()=>
         import('./modules/user-profile/user-profile.module').then(m => m.UserProfileModule)
      },{
         path: 'panel',loadChildren: ()=>
         import('./modules/panel/panel.module').then(m => m.PanelModule)
      },{
         path: 'orders', loadChildren: ()=> 
         import('./modules/orders/orders.module').then(m => m.OrdersModule)
      },{
         path: 'checks', loadChildren: ()=> 
         import('./modules/checks/checks.module').then(m => m.ChecksModule)
      },{
         path: 'permission', loadChildren: ()=> 
         import('app/permission/permission.module').then(m => m.PermissionModule),
      },{
         path: 'log-error', loadChildren: ()=> 
         import('app/log-error/log.module').then(m => m.LogModule),
      },{
         path:'cod-control', loadChildren: ()=>
         import('./modules/cod-control/cod-control.module').then(m => m.CodControlModule),
      }
      ],
   },
   {
      path: '**',
      redirectTo: 'session'
   }
   ];
  
@NgModule({
  imports: [RouterModule.forRoot(AppRoutes, {enableTracing: false})],
  exports: [RouterModule],
  providers: []
})
export class RoutingModule { }

